exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-10-simple-yet-effective-positive-affirmations-to-start-your-day-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/10-simple-yet-effective-positive-affirmations-to-start-your-day.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-10-simple-yet-effective-positive-affirmations-to-start-your-day-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-5-ways-to-make-positive-affirmations-more-effective-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/5-ways-to-make-positive-affirmations-more-effective.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-5-ways-to-make-positive-affirmations-more-effective-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-benefits-of-positive-affirmations-for-children-and-teens-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/benefits-of-positive-affirmations-for-children-and-teens.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-benefits-of-positive-affirmations-for-children-and-teens-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-creating-your-own-personalized-positive-affirmations-practice-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/creating-your-own-personalized-positive-affirmations-practice.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-creating-your-own-personalized-positive-affirmations-practice-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-how-to-incorporate-positive-affirmations-into-your-daily-routine-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/how-to-incorporate-positive-affirmations-into-your-daily-routine.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-how-to-incorporate-positive-affirmations-into-your-daily-routine-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-how-to-use-positive-affirmations-to-achieve-your-goals-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/how-to-use-positive-affirmations-to-achieve-your-goals.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-how-to-use-positive-affirmations-to-achieve-your-goals-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-positive-affirmations-for-stress-and-anxiety-relief-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/positive-affirmations-for-stress-and-anxiety-relief.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-positive-affirmations-for-stress-and-anxiety-relief-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-benefits-of-practicing-positive-affirmations-every-day-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/the-benefits-of-practicing-positive-affirmations-every-day.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-benefits-of-practicing-positive-affirmations-every-day-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-best-positive-affirmations-for-building-confidence-and-self-esteem-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/the-best-positive-affirmations-for-building-confidence-and-self-esteem.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-best-positive-affirmations-for-building-confidence-and-self-esteem-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-connection-between-positive-affirmations-and-law-of-attraction-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/the-connection-between-positive-affirmations-and-law-of-attraction.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-connection-between-positive-affirmations-and-law-of-attraction-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-impact-of-positive-affirmations-on-mental-health-and-well-being-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/the-impact-of-positive-affirmations-on-mental-health-and-well-being.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-impact-of-positive-affirmations-on-mental-health-and-well-being-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-power-of-positive-affirmations-for-overcoming-negative-thoughts-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/the-power-of-positive-affirmations-for-overcoming-negative-thoughts.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-power-of-positive-affirmations-for-overcoming-negative-thoughts-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-power-of-positive-affirmations-how-they-can-transform-your-life-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/the-power-of-positive-affirmations-how-they-can-transform-your-life.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-power-of-positive-affirmations-how-they-can-transform-your-life-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-role-of-positive-affirmations-in-personal-growth-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/the-role-of-positive-affirmations-in-personal-growth.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-role-of-positive-affirmations-in-personal-growth-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-science-behind-positive-affirmations-why-they-work-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/the-science-behind-positive-affirmations-why-they-work.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-science-behind-positive-affirmations-why-they-work-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-top-10-positive-affirmations-for-a-happy-and-fulfilling-life-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/affirme-site/affirme-site/blog/the-top-10-positive-affirmations-for-a-happy-and-fulfilling-life.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-affirme-site-affirme-site-blog-the-top-10-positive-affirmations-for-a-happy-and-fulfilling-life-mdx" */)
}

